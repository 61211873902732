import React from 'react';
import styled from 'styled-components';

import {
	EventItem,
	EventRow,
	Hero,
	MetaData,
	TitleSection,
} from '../components';
import Title from '../components/Title';

const EventsWrapper = styled.div`
	${Title} {
		margin-top: ${({ theme }) => theme.spacing[6]};
	}
`;

const Event = ({
	pageContext: { title, slug, content, acf, options, events, header },
}) => (
	<>
		<MetaData
			title={acf.meta_title || title}
			desciption={acf.meta_description}
		/>

		{header.images && !!header.images.length && (
			<Hero
				images={header.images}
				title={header.title}
				logo={header.logo && header.logo.source_url}
				link={header.link}
				linkText={header.link_text}
				linkPopup={header.link_popup}
			/>
		)}

		<TitleSection
			content={
				<EventsWrapper className="w-container events">
					<div className="w-row">
						<div className="w-col w-col-5 events__events">
							{events.map((e) => (
								<EventRow
									title={e.title}
									slug={e.slug}
									{...e.acf}
									selected={title === e.title}
								/>
							))}
						</div>

						<div className="w-col w-col-7 events__active">
							<div>
								{acf.image && (
									<img src={acf.image.source_url} alt={acf.image.alt_text} />
								)}
								<Title
									size="sm"
									color="dark"
									dangerouslySetInnerHTML={{ __html: title }}
								/>
								<p className="event-paragraph1">
									{acf.date}, {acf.start_time}
								</p>
								<div
									dangerouslySetInnerHTML={{
										__html: content,
									}}
								/>
								{acf.booking_link && (
									<a
										href={acf.booking_link}
										className="button-3 w-button mtop20 mbot30"
										target="_blank"
									>
										Book Now
									</a>
								)}
							</div>
						</div>
					</div>
				</EventsWrapper>
			}
		/>
	</>
);

export default Event;
